import type { ChangeEvent } from 'react'

import { Button, Paper, TextField, Typography, Box } from '@mui/material'
import { useForm } from 'react-hook-form'

import mastercardLogo from 'assets/images/mastercard-logos/mc_symbol.svg'
import visaLogo from 'assets/images/visa-logos/vbm_blugrad01.png'
import FormErrorMessage from 'components/common/formErrorMessage/formErrorMessage'
import { getGuestPaymentValidation } from 'utils/client/guestPaymentValidation'
import { handleFloatInput } from 'utils/common/forms'

import GuestPaymentIcon from './GuestPaymentIcon'
import cls from './GuestPaymentPage.module.scss'

import type { translateType } from 'translations/types'

interface IProps {
  t: translateType;
}

interface IFields {
  applicationNum: string;
  email: string;
  amount: string;
}

const GuestPaymentPage = ({ t }: IProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFields>()

  const validation = getGuestPaymentValidation(t)

  const onSubmit = (data: IFields) => {
    console.log({ data })
  }

  return (
    <Paper className={cls.paper}>
      <Typography
        variant="h1"
        align="center"
        mb={4}
        sx={{
          '@media screen and (max-width: 520px)': {
            fontSize: '2.1rem'
          },
          '@media screen and (max-width: 350px)': {
            fontSize: '1.8rem'
          }
        }}
      >
        {t('homePage.guestPayment.title')}
      </Typography>
      <Typography variant="h5" align="center" mb={3}>
        {t('homePage.guestPayment.subtitle')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" alignItems="center" columnGap={7} mb={3}>
          <div style={{ flexGrow: 1 }}>
            <Box mb={2}>
              <TextField
                type="number"
                label={t('homePage.guestPayment.appNum')}
                variant="standard"
                fullWidth
                {...register('applicationNum', {
                  ...validation.applicationNumber
                })}
              />
              <FormErrorMessage errors={errors} name="applicationNum" />
            </Box>

            <Box mb={2}>
              <TextField
                type='text'
                label={t('homePage.signUp.labelEmail')}
                variant="standard"
                fullWidth
                {...register('email', { ...validation.email })}
              />
              <FormErrorMessage errors={errors} name="email" />
            </Box>

            <Box mb={2}>
              <TextField
                type="text"
                label={t('homePage.guestPayment.amount')}
                variant="standard"
                fullWidth
                {...register('amount', { ...validation.amount })}
                onChange={(e) => {
                  const ev = e as ChangeEvent<HTMLInputElement>
                  handleFloatInput(ev)
                  register('amount', { ...validation.amount }).onChange(ev)
                }}
              />
              <FormErrorMessage errors={errors} name="amount" />
            </Box>
          </div>
          <GuestPaymentIcon className={cls.formIcon} />
        </Box>
        <div className={cls.paymentSystemsRow}>
          <div
            style={{ display: 'flex', columnGap: '8px', alignItems: 'center' }}
          >
            <img
              src={mastercardLogo}
              alt="Master Card"
              width="50"
              style={{ objectFit: 'contain' }}
            />
            <p style={{ margin: 0 }}>Mastercard</p>
          </div>
          <img
            src={visaLogo}
            alt="Visa"
            width="50"
            style={{ objectFit: 'contain' }}
          />
        </div>
        <Button size="large" variant="contained" type="submit" fullWidth>
          {t('homePage.guestPayment.pay')}
        </Button>
      </form>
    </Paper>
  )
}

export default GuestPaymentPage
